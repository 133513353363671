import React from "react"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import { PageWrapper, MaxContainer, Container } from "../elements"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageWrapper color="red">
      <MaxContainer>
        <Container>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
      </MaxContainer>
    </PageWrapper>
  </Layout>
)

export default NotFoundPage
